<template>
  <div class="DeptDialog">
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="$t('cip.plat.sys.dept.title.indexHeadTitle')">
    </head-layout>
    <grid-layout
      ref="gridLayOut"
      :tableOptions="option"
      :tableData="data"
      :table-loading="loading"
      @gird-handle-select-click="selectionChange"
      @grid-edit="rowUpdate"
      @grid-romve="rowDel"
      :data-total="dataTotal"
      :page="page"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-addChild="handleAdd"
    >
      <template #customBtn="{ row }">
        <el-button
          size="small"
          type="text"
          @click="roleDefBtn(row)"
          v-if="isOnlyShowPrj && row.deptCategory == 5"
        >
          确定
        </el-button>
        <el-button
          size="small"
          type="text"
          @click="roleDefBtn(row)"
          v-if="!isOnlyShowPrj"
        >
          确定
        </el-button>
      </template>
    </grid-layout>

    <div style="text-align: right" v-if="selection">
      <el-button
        size="small"
        type="primary"
        @click="handleListNodeSave">确定</el-button>
    </div>
  </div>
</template>

<script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { getSysConfig, getUserInfo } from "@/api/system/user";
import {
  getLazyList,
  remove,
  update,
  add,
  getDept,
  getDeptTree,
  getPage,
  selectChildren,
  getLazyListDept,
} from "@/api/system/dept";
import { mapGetters } from "vuex";
import website from "@/config/website";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from "@/views/components/layout/form-layout";
import { templateByEnable } from "@/api/reportTasks";

export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
  },
  props: {
    deptCategory: [],
    selection: false,
    publicParams: {
      type: Object,
      default: () => {},
    },
    parentDeptId: {
      type: String,
      default: "",
    },
    isOnlyShowPrj: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      option:{
          height:500,
          rowKey: "id",
          selection: this.selection || false,
          menu: !this.selection,
          column: [
            {
              label: this.$t("cip.plat.sys.dept.field.deptName"),
              prop: "deptName",
              search: true,
              width: 240,
              align: "left",
              overHidden: true,
            },
            {
              label: this.$t("cip.plat.sys.dept.field.fullName"),
              prop: "fullName",
              search: true,
              // width: 230,
              align: "left",
              overHidden: true,
            },
            {
              label: this.$t("cip.plat.sys.dept.field.parentId"),
              prop: "parentId",
              dicData: [],
              type: "tree",
              hide: true,
              addDisabled: false,
              props: {
                label: "title",
              },
              // width: 100,
              align: "center",
              overHidden: true,
            },
            {
              label: this.$t("cip.plat.sys.dept.field.deptCategory"),
              type: "select",
              dicUrl: "/api/sinoma-system/dict/dictionary?code=org_category",
              dataType: "number",
              align: "center",
              // width: 120,
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              prop: "deptCategory",

              overHidden: true,
            },
          ],
      },
      orgObj: {},
      isShow: false,
      dataObj: "",
      dataTotal: "",
      tenantStatus: "",
      searchForm: {},
      searchColumns: [
        {
          label: "",
          prop: "deptName",
          search: true,
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.dept.field.deptName"),
        },
        {
          label: this.$t("cip.plat.sys.dept.field.deptCategory"),
          type: "select",
          placeholder:
            this.$t("cip.cmn.rule.selectWarning") +
            this.$t("cip.plat.sys.dept.field.deptCategory"),
          dicUrl: "/api/sinoma-system/dict/dictionary?code=org_category",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
          dataType: "number",
          prop: "deptCategory",
        },
        // {
        //   label: '',
        //   prop: "tenantId",
        //   type: "select",
        //   placeholder:this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.sys.dept.field.tenantId"),
        //   dicUrl: "/api/sinoma-system/tenant/select",
        //   addDisplay: false,
        //   editDisplay: false,
        //   viewDisplay: website.tenantMode,
        //   span:4,
        //   props: {
        //     label: "tenantName",
        //     value: "tenantId"
        //   },
        //   hide: !website.tenantMode,
        //   search: website.tenantMode,
        //
        // },
        {
          label: "",
          span: 4,
          type: "input",
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.dept.field.fullName"),
          prop: "fullName",
          search: true,
        },
        {
          label: this.$t("cip.plat.sys.dept.field.isEnable"),
          prop: "isEnable",
          type: "select",
          placeholder:
            this.$t("cip.cmn.rule.selectWarning") +
            this.$t("cip.plat.sys.dept.field.isEnable"),
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
          dataType: "String",
        },
      ],
      form: {},
      selectionList: [],
      query: {},
      loading: true,
      parentId: 0,
      page: {
        pageSize: 1000,
        pageSizes: [10, 20, 30, 40, 50, 100, 1000],
        currentPage: 1,
        total: 0,
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    headBtnOptions() {
      let buttonBtn = [];
      return buttonBtn;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let buttonBtn = [];
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.defBtn"),
        emit: "role-defBtn",
        type: "text",
        icon: "",
      });
      return buttonBtn;
    },
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_del, false),
        editBtn: this.vaildData(this.permission.dept_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  methods: {
    roleDefBtn(row) {
      console.log("我是新的参数：", {
        ...row,
        oldDeptName: row.deptName,
        deptName: row.ancestorsName,
      });
      this.$emit("select-data", {
        ...row,
        oldDeptName: row.deptName,
        deptName: row.ancestorsName,
      });
    },
    getSysConfig() {
      getSysConfig().then((res) => {
        console.log("我是组织结构数据：", res.data.data);
        let data = res.data.data;
        this.tenantStatus = data.is_tenant;
      });
    },
    save() {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm;
          let requestType = obj.hasOwnProperty("id") ? update : add;
          requestType(obj).then(
            () => {
              this.parentId = 0;
              this.onLoad(this.page);
              this.initData();
              this.dataObj = "";
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess"),
              });
              this.isShow = false;
              //
            },
            (error) => {
              window.console.log(error);

              this.$refs.formLayout.$refs.form.allDisabled = false;
            }
          );
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false;
        }
      });
    },
    handleListNodeSave() {
      this.$emit("select-all-data", this.selectionList);
    },
    cancel() {
      this.isShow = false;
      this.dataObj = "";
    },
    getNew() {
      console.log(1111);
      this.$router.push({
        path: "/dept/deptEdit",
        query: {
          type: "add",
          tenantStatus: this.tenantStatus,
        },
      });
      // this.isShow = true;
    },
    headRomve() {
      this.handleDelete();
    },
    initData() {
      if (this.deptCategory !== undefined || this.deptCategory) {
        getDeptTree(null, this.deptCategory.join(",")).then((res) => {
          const column = this.findObject(this.option.column, "parentId");
          column.dicData = res.data.data;
          console.log("进行输出----------", column);
        });
      } else {
        getDeptTree().then((res) => {
          const column = this.findObject(this.option.column, "parentId");
          column.dicData = res.data.data;
          console.log("进行输出----------", column);
        });
      }
    },
    handleAdd(row) {
      this.$router.push({
        path: "/dept/deptEdit",
        query: {
          type: "addChild",
          id: row.id,
          tenantStatus: this.tenantStatus,
        },
      });
    },
    rowSave(row, done, loading) {
      add(row).then(
        (res) => {
          // 获取新增数据的相关字段
          const data = res.data.data;
          row.id = data.id;
          row.deptCategoryName = data.deptCategoryName;
          row.tenantId = data.tenantId;
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          // 数据回调进行刷新
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      let data = encodeURIComponent(JSON.stringify(row));
      this.$router.push({
        path: "/dept/deptEdit",
        query: {
          type: "edit",
          id: row.id,
          tenantStatus: this.tenantStatus,
        },
      });
      // console.log(row)
      // this.dataObj = row
      // this.isShow = true;
    },
    rowDel(row, index, done) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          this.initData();
          this.onLoad(this.page);
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;

          // 表格数据重载
          this.onLoad(this.page);
          this.initData();
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    searchReset() {
      this.query = {};
      this.parentId = 0;
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.parentId = 0;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData();
      }
      if (["edit", "view"].includes(type)) {
        getDept(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
    },
    beforeClose(done) {
      this.parentId = "";
      const column = this.findObject(this.option.column, "parentId");
      column.value = "";
      column.addDisabled = false;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },

    onLoad(page, params = {}) {
      this.loading = true;

      let parentId = this.parentDeptId || this.userInfo.dept_id;
      // console.log("parentId:", parentId);
      let deptCategory =  this.deptCategory ? this.deptCategory.join(",") : ''
      getDeptTree('',deptCategory,parentId,this.publicParams).then((res) => {
        this.data = res.data.data;
        this.$refs.gridLayOut.toggleRowExpansion(res.data.data[0].id,true)
        this.loading = false;
        this.selectionClear();
      });
    },
    treeLoad(tree, treeNode, resolve) {
      const parentId = tree.id;
      getLazyList(1, 1000, parentId, this.publicParams).then((res) => {
        resolve(res.data.data.records);
      });
    },
  },
  created() {},
  mounted() {
    this.initData();
    this.getSysConfig();
    this.onLoad(this.page);
  },
};
</script>

<style lang="scss" scoped>
::v-deep.DeptDialog .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
