var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DeptDialog" },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.plat.sys.dept.title.indexHeadTitle"),
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          tableOptions: _vm.option,
          tableData: _vm.data,
          "table-loading": _vm.loading,
          gridRowBtn: _vm.gridRowBtn,
          "data-total": _vm.dataTotal,
          page: _vm.page,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "grid-edit": _vm.rowUpdate,
          "grid-romve": _vm.rowDel,
          "role-defBtn": _vm.roleDefBtn,
          "tree-load": _vm.treeLoad,
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-addChild": _vm.handleAdd,
        },
      }),
      _vm.selection
        ? _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleListNodeSave },
                },
                [_vm._v("\n      确定\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }